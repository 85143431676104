import { DestroyRef, Directive, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { BehaviorSubject, tap } from 'rxjs';
import { HungarianNativeMonthPickerAdapter } from '../adapters';
import { startOfMonthInBudapestTimeZone } from '../../../shared/utils/dates';

@Directive({
  selector: '[appMonthPicker]',
  standalone: false,
  providers: [
    {
      provide: DateAdapter,
      useFactory: () => {
        const DEFAULT_FORMAT = 'yyyy-MM';
        return new HungarianNativeMonthPickerAdapter(new BehaviorSubject<string>(DEFAULT_FORMAT));
      },
    },
  ],
})
export class NativeMonthPickerDirective implements OnInit {
  /** The datepicker instance. */
  @Input({ required: true, alias: 'appMonthPicker' })
  public monthPicker!: MatDatepicker<Date>;

  /** The control to which the date will be set when a month is selected. */
  @Input({ required: true })
  public formControl!: FormControl<Date | null>;

  /**
   * The display format of the date. Default is 'yyyy-MM'.
   * This setter updates the display format of the date adapter.
   */
  @Input()
  public set displayFormat(displayFormat: string) {
    (this.adapter as HungarianNativeMonthPickerAdapter).displayFormat.next(displayFormat);
  }

  constructor(
    private adapter: DateAdapter<Date>,
    private destroyRef: DestroyRef
  ) {}

  public ngOnInit(): void {
    this.initMonthPickerProperties();
    this.listenOnMonthSelected(this.monthPicker);
  }

  /** Initializes the month picker properties. */
  private initMonthPickerProperties(): void {
    this.monthPicker.panelClass = 'month-picker';
    this.monthPicker.startView = 'year';
  }

  /** Listening on the monthSelected event to update the date control's value and prevent showing day selector. */
  private listenOnMonthSelected(datePicker: MatDatepicker<Date>): void {
    datePicker.monthSelected
      .pipe(
        tap(date => this.monthSelected(date, datePicker)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  /** Handles the selection of a month. In our case we only allow selecting months but not days.
   *
   * @param date The selected date.
   * @param datepicker The date picker object to close.
   */
  private monthSelected(date: Date, datepicker: MatDatepicker<Date>) {
    /** After selecting the date we manually close the date-picker to prevent showing day selector. */
    datepicker.close();

    /** The monthSelected event doesn't set a date, so we need to set it manually. */
    this.formControl.setValue(startOfMonthInBudapestTimeZone(new Date(date)));
  }
}
