import { Pipe, PipeTransform } from '@angular/core';

/** Breaks the text if it contains `</br>` tag. */
@Pipe({
  name: 'textWithLineBreak',
  standalone: false,
})
export class TextWithLineBreakPipe implements PipeTransform {
  transform(text: string | null): string[] | null {
    return text !== null ? text.split('</br>') : null;
  }
}
