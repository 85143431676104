import "core-js/modules/es.error.cause.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == typeof i ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != typeof t || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != typeof i) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import { BaseAction } from "./_base.mjs";
import { getMoves } from "../../../helpers/moves.mjs";
/**
 * Action that tracks row move changes.
 *
 * @class RowMoveAction
 * @private
 */
export class RowMoveAction extends BaseAction {
  constructor(_ref) {
    let {
      rows,
      finalIndex
    } = _ref;
    super();
    /**
     * @param {number[]} rows An array with moved rows.
     */
    _defineProperty(this, "rows", void 0);
    /**
     * @param {number} finalIndex The destination index.
     */
    _defineProperty(this, "finalRowIndex", void 0);
    this.rows = rows.slice();
    this.finalRowIndex = finalIndex;
  }
  static startRegisteringEvents(hot, undoRedoPlugin) {
    hot.addHook('beforeRowMove', (rows, finalIndex) => {
      if (rows === false) {
        return;
      }
      undoRedoPlugin.done(() => new RowMoveAction({
        rows,
        finalIndex
      }));
    });
  }

  /**
   * @param {Core} hot The Handsontable instance.
   * @param {function(): void} undoneCallback The callback to be called after the action is undone.
   */
  undo(hot, undoneCallback) {
    const manualRowMove = hot.getPlugin('manualRowMove');
    hot.addHookOnce('afterViewRender', undoneCallback);
    const rowMoves = getMoves(this.rows, this.finalRowIndex, hot.rowIndexMapper.getNumberOfIndexes());
    rowMoves.reverse().forEach(_ref2 => {
      let {
        from,
        to
      } = _ref2;
      if (from < to) {
        to -= 1;
      }
      manualRowMove.moveRow(to, from);
    });
    hot.render();
    hot.deselectCell();
    hot.selectRows(this.rows[0], this.rows[0] + this.rows.length - 1);
  }

  /**
   * @param {Core} hot The Handsontable instance.
   * @param {function(): void} redoneCallback The callback to be called after the action is redone.
   */
  redo(hot, redoneCallback) {
    const manualRowMove = hot.getPlugin('manualRowMove');
    hot.addHookOnce('afterViewRender', redoneCallback);
    manualRowMove.moveRows(this.rows.slice(), this.finalRowIndex);
    hot.render();
    hot.deselectCell();
    hot.selectRows(this.finalRowIndex, this.finalRowIndex + this.rows.length - 1);
  }
}