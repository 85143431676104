import "core-js/modules/es.error.cause.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == typeof i ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != typeof t || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != typeof i) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import { BaseAction } from "./_base.mjs";
import { getMoves } from "../../../helpers/moves.mjs";
/**
 * Action that tracks column move changes.
 *
 * @class ColumnMoveAction
 * @private
 */
export class ColumnMoveAction extends BaseAction {
  constructor(_ref) {
    let {
      columns,
      finalIndex
    } = _ref;
    super();
    /**
     * @param {number[]} columns An array with moved columns.
     */
    _defineProperty(this, "columns", void 0);
    /**
     * @param {number} finalIndex The destination index.
     */
    _defineProperty(this, "finalColumnIndex", void 0);
    this.columns = columns.slice();
    this.finalColumnIndex = finalIndex;
  }
  static startRegisteringEvents(hot, undoRedoPlugin) {
    hot.addHook('beforeColumnMove', (columns, finalIndex) => {
      if (columns === false) {
        return;
      }
      undoRedoPlugin.done(() => new ColumnMoveAction({
        columns,
        finalIndex
      }));
    });
  }

  /**
   * @param {Core} hot The Handsontable instance.
   * @param {function(): void} undoneCallback The callback to be called after the action is undone.
   */
  undo(hot, undoneCallback) {
    const manualColumnMove = hot.getPlugin('manualColumnMove');
    hot.addHookOnce('afterViewRender', undoneCallback);
    const columnMoves = getMoves(this.columns, this.finalColumnIndex, hot.columnIndexMapper.getNumberOfIndexes());
    columnMoves.reverse().forEach(_ref2 => {
      let {
        from,
        to
      } = _ref2;
      if (from < to) {
        to -= 1;
      }
      manualColumnMove.moveColumn(to, from);
    });
    hot.render();
    hot.deselectCell();
    hot.selectColumns(this.columns[0], this.columns[0] + this.columns.length - 1);
  }

  /**
   * @param {Core} hot The Handsontable instance.
   * @param {function(): void} redoneCallback The callback to be called after the action is redone.
   */
  redo(hot, redoneCallback) {
    const manualColumnMove = hot.getPlugin('manualColumnMove');
    hot.addHookOnce('afterViewRender', redoneCallback);
    manualColumnMove.moveColumns(this.columns.slice(), this.finalColumnIndex);
    hot.render();
    hot.deselectCell();
    hot.selectColumns(this.finalColumnIndex, this.finalColumnIndex + this.columns.length - 1);
  }
}