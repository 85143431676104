import "core-js/modules/es.error.cause.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == typeof i ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != typeof t || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != typeof i) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import { BaseAction } from "./_base.mjs";
/**
 * Action that tracks filter changes.
 *
 * @class FiltersAction
 * @private
 */
export class FiltersAction extends BaseAction {
  constructor(_ref) {
    let {
      conditionsStack,
      previousConditionsStack
    } = _ref;
    super();
    /**
     * @param {Array} previousConditionsStack An array of the previous filter conditions.
     */
    _defineProperty(this, "conditionsStack", void 0);
    /**
     * @param {Array} conditionsStack An array of the filter conditions.
     */
    _defineProperty(this, "previousConditionsStack", void 0);
    this.conditionsStack = conditionsStack;
    this.previousConditionsStack = previousConditionsStack;
  }
  static startRegisteringEvents(hot, undoRedoPlugin) {
    hot.addHook('beforeFilter', (conditionsStack, previousConditionsStack) => {
      undoRedoPlugin.done(() => new FiltersAction({
        conditionsStack,
        previousConditionsStack
      }));
    });
  }

  /**
   * @param {Core} hot The Handsontable instance.
   * @param {function(): void} undoneCallback The callback to be called after the action is undone.
   */
  undo(hot, undoneCallback) {
    const filters = hot.getPlugin('filters');
    hot.addHookOnce('afterViewRender', undoneCallback);
    filters.conditionCollection.importAllConditions(this.previousConditionsStack);
    filters.filter();
  }

  /**
   * @param {Core} hot The Handsontable instance.
   * @param {function(): void} redoneCallback The callback to be called after the action is redone.
   */
  redo(hot, redoneCallback) {
    const filters = hot.getPlugin('filters');
    hot.addHookOnce('afterViewRender', redoneCallback);
    filters.conditionCollection.importAllConditions(this.conditionsStack);
    filters.filter();
  }
}