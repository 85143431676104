import "core-js/modules/es.error.cause.js";
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == typeof i ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != typeof t || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != typeof i) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import { BaseAction } from "./_base.mjs";
/**
 * Action that tracks column sort changes.
 *
 * @class ColumnSortAction
 * @private
 */
export class ColumnSortAction extends BaseAction {
  constructor(_ref) {
    let {
      currentSortState,
      newSortState
    } = _ref;
    super();
    /**
     * @param {Array} currentSortState The current sort state.
     */
    _defineProperty(this, "previousSortState", void 0);
    /**
     * @param {Array} newSortState The new sort state.
     */
    _defineProperty(this, "nextSortState", void 0);
    this.previousSortState = currentSortState;
    this.nextSortState = newSortState;
  }
  static startRegisteringEvents(hot, undoRedoPlugin) {
    hot.addHook('beforeColumnSort', (currentSortState, newSortState, sortPossible) => {
      if (!sortPossible) {
        return;
      }
      undoRedoPlugin.done(() => new ColumnSortAction({
        currentSortState,
        newSortState
      }));
    });
  }

  /**
   * @param {Core} hot The Handsontable instance.
   * @param {function(): void} undoneCallback The callback to be called after the action is undone.
   */
  undo(hot, undoneCallback) {
    const sortPlugin = hot.getPlugin('columnSorting');
    const multiSortPlugin = hot.getPlugin('multiColumnSorting');
    const enabledSortPlugin = multiSortPlugin.isEnabled() ? multiSortPlugin : sortPlugin;
    if (this.previousSortState.length) {
      enabledSortPlugin.sort(this.previousSortState);
    } else {
      enabledSortPlugin.clearSort();
    }
    undoneCallback();
  }

  /**
   * @param {Core} hot The Handsontable instance.
   * @param {function(): void} redoneCallback The callback to be called after the action is redone.
   */
  redo(hot, redoneCallback) {
    const sortPlugin = hot.getPlugin('columnSorting');
    const multiSortPlugin = hot.getPlugin('multiColumnSorting');
    const enabledSortPlugin = multiSortPlugin.isEnabled() ? multiSortPlugin : sortPlugin;
    enabledSortPlugin.sort(this.nextSortState);
    redoneCallback();
  }
}