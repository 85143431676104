import "core-js/modules/es.error.cause.js";
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == typeof i ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != typeof t || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != typeof i) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import { BaseAction } from "./_base.mjs";
import { align } from "../../contextMenu/utils.mjs";
import { arrayEach } from "../../../helpers/array.mjs";
/**
 * Action that tracks cell alignment changes.
 *
 * @class CellAlignmentAction
 * @private
 */
export class CellAlignmentAction extends BaseAction {
  constructor(_ref) {
    let {
      stateBefore,
      range,
      type,
      alignment
    } = _ref;
    super();
    /**
     * @param {Array} stateBefore The previous state.
     */
    _defineProperty(this, "stateBefore", void 0);
    /**
     * @param {object} range The cell range.
     */
    _defineProperty(this, "range", void 0);
    /**
     * @param {string} type The type of the alignment ("top", "left", "bottom" or "right").
     */
    _defineProperty(this, "type", void 0);
    /**
     * @param {string} alignment The alignment CSS class.
     */
    _defineProperty(this, "alignment", void 0);
    this.stateBefore = stateBefore;
    this.range = range;
    this.type = type;
    this.alignment = alignment;
  }
  static startRegisteringEvents(hot, undoRedoPlugin) {
    hot.addHook('beforeCellAlignment', (stateBefore, range, type, alignment) => {
      undoRedoPlugin.done(() => new CellAlignmentAction({
        stateBefore,
        range,
        type,
        alignment
      }));
    });
  }

  /**
   * @param {Core} hot The Handsontable instance.
   * @param {function(): void} undoneCallback The callback to be called after the action is undone.
   */
  undo(hot, undoneCallback) {
    arrayEach(this.range, range => {
      range.forAll((row, col) => {
        // Alignment classes should only collected within cell ranges. We skip header coordinates.
        if (row >= 0 && col >= 0) {
          hot.setCellMeta(row, col, 'className', this.stateBefore[row][col] || ' htLeft');
        }
      });
    });
    hot.addHookOnce('afterViewRender', undoneCallback);
    hot.render();
  }

  /**
   * @param {Core} hot The Handsontable instance.
   * @param {function(): void} redoneCallback The callback to be called after the action is redone.
   */
  redo(hot, redoneCallback) {
    align(this.range, this.type, this.alignment, (row, col) => hot.getCellMeta(row, col), (row, col, key, value) => hot.setCellMeta(row, col, key, value));
    hot.addHookOnce('afterViewRender', redoneCallback);
    hot.render();
  }
}