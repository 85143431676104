import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { BehaviorSubject } from 'rxjs';
import { HungarianNativeDateAdapter } from '../../../hungarian-native-date-adapter.class';
import { formatInBudapestTimeZone } from '../../../shared/utils/dates';

@Injectable()
export class HungarianNativeMonthPickerAdapter extends HungarianNativeDateAdapter {
  public displayFormat: BehaviorSubject<string>;

  constructor(displayFormat: BehaviorSubject<string>) {
    super();
    this.displayFormat = displayFormat;
  }

  public override format(date: Date): string {
    return formatInBudapestTimeZone(date, this.displayFormat.value);
  }

  public override parse(value: string): Date | null {
    return DateTime.fromFormat(value, this.displayFormat.value).toJSDate();
  }
}
