import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.unshift.js";
import "core-js/modules/esnext.iterator.map.js";
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == typeof i ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != typeof t || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != typeof i) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
/**
 * The view order is a list of indexes that represent the order of the rendered elements.
 *
 * @class {ViewOrder}
 */
export class ViewOrder {
  constructor(viewOffset, viewSize) {
    _defineProperty(this, "order", []);
    this.order = [...Array(viewSize).keys()].map(i => viewOffset + i);
  }

  /**
   * The length of the view order.
   *
   * @returns {number}
   */
  get length() {
    return this.order.length;
  }

  /**
   * Checks if the view order contains the offset index.
   *
   * @param {number} offsetIndex The offset index.
   * @returns {boolean}
   */
  has(offsetIndex) {
    return this.order.indexOf(offsetIndex) > -1;
  }

  /**
   * Gets the offset index at the given zero-based index. If the index
   * is out of bounds, -1 is returned.
   *
   * @param {number} zeroBasedIndex The zero-based index.
   * @returns {number}
   */
  get(zeroBasedIndex) {
    return zeroBasedIndex < this.order.length ? this.order[zeroBasedIndex] : -1;
  }

  /**
   * Removes the offset index from the view order.
   *
   * @param {number} offsetIndex The offset index.
   */
  remove(offsetIndex) {
    this.order.splice(this.order.indexOf(offsetIndex), 1);
  }

  /**
   * Prepends the offset index to the view order. To keep the order length constant,
   * the last offset index is removed.
   *
   * @param {number} offsetIndex The offset index.
   * @returns {number}
   */
  prepend(offsetIndex) {
    this.order.unshift(offsetIndex);
    return this.order.pop();
  }
}