import "core-js/modules/es.error.cause.js";
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == typeof i ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != typeof t || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != typeof i) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import { BaseAction } from "./_base.mjs";
/**
 * Action that tracks column creation.
 *
 * @class CreateColumnAction
 * @private
 */
export class CreateColumnAction extends BaseAction {
  constructor(_ref) {
    let {
      index,
      amount
    } = _ref;
    super();
    /**
     * @param {number} index The visual column index.
     */
    _defineProperty(this, "index", void 0);
    /**
     * @param {number} amount The number of created columns.
     */
    _defineProperty(this, "amount", void 0);
    this.index = index;
    this.amount = amount;
  }
  static startRegisteringEvents(hot, undoRedoPlugin) {
    hot.addHook('afterCreateCol', (index, amount, source) => {
      undoRedoPlugin.done(() => new CreateColumnAction({
        index,
        amount
      }), source);
    });
  }

  /**
   * @param {Core} hot The Handsontable instance.
   * @param {function(): void} undoneCallback The callback to be called after the action is undone.
   */
  undo(hot, undoneCallback) {
    hot.addHookOnce('afterRemoveCol', undoneCallback);
    hot.alter('remove_col', this.index, this.amount, 'UndoRedo.undo');
  }

  /**
   * @param {Core} hot The Handsontable instance.
   * @param {function(): void} redoneCallback The callback to be called after the action is redone.
   */
  redo(hot, redoneCallback) {
    hot.addHookOnce('afterCreateCol', redoneCallback);
    hot.alter('insert_col_start', this.index, this.amount, 'UndoRedo.redo');
  }
}