import { Pipe, PipeTransform } from '@angular/core';
import { AssignValueOption } from '../interfaces';

@Pipe({
  name: 'assignValueOptionDisplay',
  standalone: false,
})
export class AssignValueOptionDisplayPipe implements PipeTransform {
  public transform(option: AssignValueOption): string {
    return (
      option.text +
      (option.disabled
        ? option.active === false
          ? ' (inactive, assigned)'
          : ' (assigned)'
        : option.active === false
          ? ' (inactive)'
          : '')
    );
  }
}
