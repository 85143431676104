import "core-js/modules/es.error.cause.js";
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == typeof i ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != typeof t || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != typeof i) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import { BaseAction } from "./_base.mjs";
/**
 * Action that tracks changes in merged cells.
 *
 * @class MergeCellsAction
 * @private
 */
export class MergeCellsAction extends BaseAction {
  constructor(_ref) {
    let {
      data,
      cellRange
    } = _ref;
    super();
    _defineProperty(this, "cellRange", void 0);
    this.cellRange = cellRange;
    this.data = data;
  }
  static startRegisteringEvents(hot, undoRedoPlugin) {
    hot.addHook('beforeMergeCells', (cellRange, auto) => {
      if (auto) {
        return;
      }
      const topStartCorner = cellRange.getTopStartCorner();
      const bottomEndCorner = cellRange.getBottomEndCorner();
      const data = hot.getData(topStartCorner.row, topStartCorner.col, bottomEndCorner.row, bottomEndCorner.col);
      undoRedoPlugin.done(() => new MergeCellsAction({
        data,
        cellRange
      }));
    });
  }

  /**
   * @param {Core} hot The Handsontable instance.
   * @param {function(): void} undoneCallback The callback to be called after the action is undone.
   */
  undo(hot, undoneCallback) {
    const mergeCellsPlugin = hot.getPlugin('mergeCells');
    hot.addHookOnce('afterViewRender', undoneCallback);
    mergeCellsPlugin.unmergeRange(this.cellRange, true);
    const topStartCorner = this.cellRange.getTopStartCorner();
    hot.populateFromArray(topStartCorner.row, topStartCorner.col, this.data, undefined, undefined, 'MergeCells');
  }

  /**
   * @param {Core} hot The Handsontable instance.
   * @param {function(): void} redoneCallback The callback to be called after the action is redone.
   */
  redo(hot, redoneCallback) {
    const mergeCellsPlugin = hot.getPlugin('mergeCells');
    hot.addHookOnce('afterViewRender', redoneCallback);
    mergeCellsPlugin.mergeRange(this.cellRange);
  }
}