import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  DaScheduleSendingStatusResponse,
  DaScheduleSendingStatusResponseMeta,
  ScheduleHeadResponse,
  ScheduleResponse,
} from '../interfaces/responses';
import { ApiResponse } from '../../../shared/interfaces';
import { HttpClient } from '@angular/common/http';
import { ScheduleType } from '../../../shared/enums';
import { ScheduleTimeSeriesRequest } from '../interfaces/requests';
import { environment } from '../../../../environments/environment';
import { formatInBudapestTimeZone } from '../../../shared/utils/dates';
import { MekiPodType } from '../enums';
import { filterOutNullishProperties } from '../../../shared/utils';

/**
 * Provides access to the MEKI schedules API endpoints,
 * and provides functionalities related to MEKI schedules.
 */
@Injectable()
export class MekiSchedulesService {
  constructor(private readonly http: HttpClient) {}

  /**
   * Retrieves the SEK schedule heads for the target day, schedule type, and tenant.
   *
   * @param params.scheduleType The target schedule type, can be either `A01`, `A02`, or `A04`.
   * @param params.date It can be any date object for the target day.
   */
  public listSekScheduleHeads(params: { scheduleType: ScheduleType; date: Date }): Observable<ScheduleHeadResponse[]> {
    return this.http
      .get<
        ApiResponse<ScheduleHeadResponse[]>
      >(`${environment.apiBasePath}/v1/meki/schedules/SEK/${params.scheduleType}/${formatInBudapestTimeZone(params.date, 'yyyy-MM-dd')}`)
      .pipe(map(response => response?.payload));
  }

  /**
   * Returns a sub-set of a full SEK schedule that the current user is allowed to see.
   *
   * @param params.scheduleType The target schedule type, can be either `A01`, `A02`, or `A04`.
   * @param params.businessDay The target day in `yyyy-MM-dd` format.
   * @param params.version The internal version number of the target schedule.
   * @param params.tenant The unique identifier of the target tenant.
   * @param params.podCodesThe unique codes of the POD. This filter is processed when requesting an `A01` (production) or `A04` (consumption) schedule.
   * @param params.partnerEicCodes The unique EIC codes of the partner. This filter is processed when requesting an `A02` (domestic trade) schedule.
   * @param params.podType An array of POD types whose schedules are returned. If not provided, schedules for main and aggregator PODs are returned.
   * @param params.noFilter Optional flag to not filter by PODs or partnerEicCodes. If not provided, values will be returned as 'false' was sent. If set to true, then 'podCodes' and 'partnerEicCodes' must be empty.
   */
  public getSekSchedule(params: {
    scheduleType: ScheduleType;
    businessDay: string;
    version: 'cnf' | 'edge' | number;
    tenant?: string;
    podCodes?: string[];
    partnerEicCodes?: string[];
    podType?: MekiPodType[];
    noFilter?: boolean;
  }): Observable<ScheduleResponse> {
    return this.http
      .get<ApiResponse<ScheduleResponse>>(
        `${environment.apiBasePath}/v1/meki/schedules/SEK/${params.scheduleType}/${params.businessDay}/${params.version}?`,
        {
          params: filterOutNullishProperties({
            tenant: params.tenant,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            pod_code: params.podCodes,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            partner_eic_code: params.partnerEicCodes,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            pod_type: params.podType,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            no_filter: params.noFilter,
          }),
        }
      )
      .pipe(map(response => response.payload));
  }

  /**
   * Modifies the target SEK schedule with the given delta values for the target day, schedule type, and tenant.
   * Modification cannot be success if the given latest known version differs from the actual latest version.
   * This endpoint uses the `delta` MEKI schedule mode, so does not need to get a full schedule for a doy.
   *
   * @param params.scheduleType The target schedule type, can be either `A01`, `A02`, or `A04`.
   * @param params.date It can be any date object for the target day.
   * @param params.latestKnownVersion The latest known version number by the UI of the target schedule.
   * @param params.tenant The unique identifier of the target tenant. Must not exceed 16 characters.
   * @param timeSeries The delta time block values to modify the target schedule..
   */
  public sendDeltaMekiSchedule(
    params: {
      scheduleType: ScheduleType;
      date: Date;
      latestKnownVersion: number;
      tenant: string;
    },
    timeSeries: ScheduleTimeSeriesRequest[]
  ): Observable<void> {
    return this.http
      .post<ApiResponse<void>>(
        `${environment.apiBasePath}/v1/meki/schedules/SEK/${params.scheduleType}/${formatInBudapestTimeZone(
          params.date,
          'yyyy-MM-dd'
        )}`,
        {
          priority: 1,
          measurementUnit: `MAW`,
          resolution: `PT15M`,
          timeSeries,
        },
        {
          params: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            latest_known_version: params.latestKnownVersion,
            tenant: params.tenant,
          },
        }
      )
      .pipe(map(() => undefined));
  }

  public sendDayAheadSchedule(
    params: {
      scheduleType: ScheduleType;
      date: Date;
    },
    timeSeries: ScheduleTimeSeriesRequest[]
  ): Observable<void> {
    return this.http
      .post<ApiResponse<void>>(
        `${environment.apiBasePath}/v1/meki/schedules/SEK/${params.scheduleType}/${formatInBudapestTimeZone(
          params.date,
          'yyyy-MM-dd'
        )}/da`,
        {
          priority: 1,
          measurementUnit: `MAW`,
          resolution: `PT15M`,
          timeSeries,
        }
      )
      .pipe(map(() => undefined));
  }

  public getDefaultSchedule(params: {
    scheduleType: ScheduleType;
    date: Date;
    podType?: MekiPodType.Sub | MekiPodType.Aggr;
  }): Observable<ScheduleResponse> {
    return this.http
      .get<
        ApiResponse<ScheduleResponse>
      >(`${environment.apiBasePath}/v1/hupx-dam/default-schedule/${params.scheduleType}/${formatInBudapestTimeZone(params.date, 'yyyy-MM-dd')}${params.podType ? '?pod_type=' + params.podType : ''}`)
      .pipe(map(response => response?.payload));
  }

  /**
   * Gets the SEK DA Schedule Sending Status for a given day and type.
   *
   * @param params.date date of the requested status.
   * @param params.scheduleType schedule type of the requested status.
   */
  public getDaSendingStatus(params: {
    scheduleType: ScheduleType;
    date: Date;
  }): Observable<ApiResponse<DaScheduleSendingStatusResponse, DaScheduleSendingStatusResponseMeta>> {
    return this.http.get<ApiResponse<DaScheduleSendingStatusResponse, DaScheduleSendingStatusResponseMeta>>(
      `${environment.apiBasePath}/v1/meki/da-sending-status/${params.scheduleType}/${formatInBudapestTimeZone(params.date, 'yyyy-MM-dd')}`
    );
  }

  /**
   * Downloads A01, A02 and A04 schedules as an .xlsx file by date.
   *
   * @param date The selected date in `yyyy-MM-dd` format.
   * @param podType The Production and Consumption schedules for PODs of the given types are included.
   */
  public downloadScheduleReportAsXlsx(date: string, podType: MekiPodType[]): Observable<BlobPart> {
    return this.http.get<BlobPart>(`${environment.apiBasePath}/v1/hupx-dam-report/schedule`, {
      responseType: 'arraybuffer' as 'json',
      params: filterOutNullishProperties({
        date,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        pod_type: podType,
      }),
    });
  }
}
