/** Defines the type of the report. */
export enum TradeOverviewReportType {
  /** Forward trade. */
  FWD = 'FWD',

  /** Intraday trade. */
  ID = 'ID',

  /** Day ahead market allocation. */
  DAM = 'DAM',

  /** Intraday auction types. */
  IDA1 = 'IDA1',
  IDA2 = 'IDA2',
  IDA3 = 'IDA3',
}
