import { DateTime } from 'luxon';

/**
 * Calculates the day difference between the given two dates. It returns integer values,
 * and only whole days are counted, which means if the difference is one milli less, then two days, than
 * the result will be only 1.
 */
export function differenceInDays(from: Date, until: Date): number {
  const zonedFrom = DateTime.fromJSDate(from).setZone('Europe/Budapest');
  const zonedUntil = DateTime.fromJSDate(until).setZone('Europe/Budapest');

  /** To get integer number on the result, we should include the "previous" unit as well (days -> hours).*/
  return zonedFrom.diff(zonedUntil, ['days', 'hours']).get('days');
}
