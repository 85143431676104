import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == typeof i ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != typeof t || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != typeof i) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import { BaseAction } from "./_base.mjs";
import { getCellMetas } from "../utils.mjs";
import { deepClone } from "../../../helpers/object.mjs";
/**
 * Action that tracks changes in row removal.
 *
 * @class RemoveRowAction
 * @private
 */
export class RemoveRowAction extends BaseAction {
  constructor(_ref) {
    let {
      index,
      data,
      fixedRowsBottom,
      fixedRowsTop,
      rowIndexesSequence,
      removedCellMetas
    } = _ref;
    super();
    /**
     * @param {number} index The visual row index.
     */
    _defineProperty(this, "index", void 0);
    /**
     * @param {Array} data The removed data.
     */
    _defineProperty(this, "data", void 0);
    /**
     * @param {number} fixedRowsBottom Number of fixed rows on the bottom. Remove row action change it sometimes.
     */
    _defineProperty(this, "fixedRowsBottom", void 0);
    /**
     * @param {number} fixedRowsTop Number of fixed rows on the top. Remove row action change it sometimes.
     */
    _defineProperty(this, "fixedRowsTop", void 0);
    /**
     * @param {Array} rowIndexesSequence Row index sequence taken from the row index mapper.
     */
    _defineProperty(this, "rowIndexesSequence", void 0);
    /**
     * @param {Array} removedCellMetas List of removed cell metas.
     */
    _defineProperty(this, "removedCellMetas", void 0);
    this.index = index;
    this.data = data;
    this.fixedRowsBottom = fixedRowsBottom;
    this.fixedRowsTop = fixedRowsTop;
    this.rowIndexesSequence = rowIndexesSequence;
    this.removedCellMetas = removedCellMetas;
  }
  static startRegisteringEvents(hot, undoRedoPlugin) {
    hot.addHook('beforeRemoveRow', (index, amount, logicRows, source) => {
      const wrappedAction = () => {
        const physicalRowIndex = hot.toPhysicalRow(index);
        const lastRowIndex = physicalRowIndex + amount - 1;
        const removedData = deepClone(hot.getSourceData(physicalRowIndex, 0, physicalRowIndex + amount - 1, hot.countSourceCols() - 1));
        return new RemoveRowAction({
          index: physicalRowIndex,
          data: removedData,
          fixedRowsBottom: hot.getSettings().fixedRowsBottom,
          fixedRowsTop: hot.getSettings().fixedRowsTop,
          rowIndexesSequence: hot.rowIndexMapper.getIndexesSequence(),
          removedCellMetas: getCellMetas(hot, physicalRowIndex, lastRowIndex, 0, hot.countCols() - 1)
        });
      };
      undoRedoPlugin.done(wrappedAction, source);
    });
  }

  /**
   * @param {Core} hot The Handsontable instance.
   * @param {function(): void} undoneCallback The callback to be called after the action is undone.
   */
  undo(hot, undoneCallback) {
    const settings = hot.getSettings();
    const changes = [];

    // Changing by the reference as `updateSettings` doesn't work the best.
    settings.fixedRowsBottom = this.fixedRowsBottom;
    settings.fixedRowsTop = this.fixedRowsTop;

    // Prepare the change list to fill the source data.
    this.data.forEach((dataRow, rowIndexDelta) => {
      Object.keys(dataRow).forEach(columnProp => {
        const columnIndex = parseInt(columnProp, 10);
        changes.push([this.index + rowIndexDelta, isNaN(columnIndex) ? columnProp : columnIndex, dataRow[columnProp]]);
      });
    });
    hot.alter('insert_row_above', this.index, this.data.length, 'UndoRedo.undo');
    this.removedCellMetas.forEach(_ref2 => {
      let [rowIndex, columnIndex, cellMeta] = _ref2;
      hot.setCellMetaObject(rowIndex, columnIndex, cellMeta);
    });
    hot.addHookOnce('afterViewRender', undoneCallback);
    hot.setSourceDataAtCell(changes, null, null, 'UndoRedo.undo');
    hot.rowIndexMapper.setIndexesSequence(this.rowIndexesSequence);
  }

  /**
   * @param {Core} hot The Handsontable instance.
   * @param {function(): void} redoneCallback The callback to be called after the action is redone.
   */
  redo(hot, redoneCallback) {
    hot.addHookOnce('afterRemoveRow', redoneCallback);
    hot.alter('remove_row', this.index, this.data.length, 'UndoRedo.redo');
  }
}